import { Component } from '@angular/core';
import { MONLOG_DETAILS_CONTAINER } from 'src/app/constants/common.constants';
import { TabService } from 'src/app/core/services/tab.service';
import { EnvironmentService } from 'src/app/services/environment.service';

type FieldVisionMessage = {
  type: 'openLog';
  id: string;
};

@Component({
  selector: 'app-field-vision-embed',
  templateUrl: './field-vision-embed.component.html',
  styleUrls: ['./field-vision-embed.component.scss'],
})
export class FieldVisionEmbedComponent {
  fieldVisionUrl: string;

  constructor(private _env: EnvironmentService) {}

  ngOnInit() {
    TabService.getInstance().updateActiveTabLabel('Field Vision');
    this.fieldVisionUrl = this._env.getFieldVisionUrl();
    window.addEventListener(
      'message',
      (e: MessageEvent) => {
        this.handleMessage(e);
      },
      false
    );
  }

  ngOnDestroy() {
    window.removeEventListener('message', this.handleMessage);
  }

  handleMessage(event: MessageEvent) {
    if (
      event.origin.replace(/\/+$/, '') !==
      this.fieldVisionUrl.replace(/\/+$/, '')
    ) {
      return;
    }
    const data: FieldVisionMessage = event.data;
    if (data.type === 'openLog') {
      this.openMonLog(data.id);
    }
  }

  openMonLog(monLogIdKey: string) {
    const tab = TabService.getInstance().buildNewTab(
      MONLOG_DETAILS_CONTAINER,
      true,
      null,
      monLogIdKey
    );
    TabService.getInstance().openTab(tab);
  }
}
