import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapsRoutingModule } from './maps-routing.module';
import { CoreModule } from '../core/core.module';
import { TranslateModule } from '@ngx-translate/core';
import { ToastModule } from 'primeng/toast';
import { DialogModule } from 'primeng/dialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DropdownModule } from 'primeng/dropdown';
import { SpinnerModule } from 'primeng/spinner';
import { DividerModule } from 'primeng/divider';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { GisMapComponent } from './gis-map/gis-map.component';
import { FieldVisionEmbedComponent } from './field-vision-embed/field-vision-embed.component';
import { SafeUrlPipe } from '../pipes/safe-url.pipe';

@NgModule({
  declarations: [GisMapComponent, FieldVisionEmbedComponent],
  imports: [
    CommonModule,
    MapsRoutingModule,
    CoreModule,
    ToastModule,
    DialogModule,
    DropdownModule,
    ProgressSpinnerModule,
    DividerModule,
    SpinnerModule,
    TableModule,
    ConfirmDialogModule,
    SafeUrlPipe,
    TranslateModule.forChild({}),
  ],
  exports: [GisMapComponent, FieldVisionEmbedComponent],
})
export class MapsModule {}
