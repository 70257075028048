import { Component } from '@angular/core';
import { TabService } from 'src/app/core/services/tab.service';

@Component({
  selector: 'app-gis-map',
  templateUrl: './gis-map.component.html',
  styleUrls: ['./gis-map.component.scss'],
})
export class GisMapComponent {
  ngOnInit() {
    TabService.getInstance().updateActiveTabLabel('GIS Field Asset Map');
  }
}
